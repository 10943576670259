import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
// import img from '../assets/images/bilhar.jpg'

const QUERY = graphql`
  query {
    images: allFile(filter: {relativePath: {eq: "bilhar.jpg"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 608) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Empresa = () => {
  const { images } = useStaticQuery(QUERY)
  return (
    <Layout>
      <SEO description='Nosso objetivo é trazer a você conforto e satisfação.' />
      <h1 className='invisible'>Empresa</h1>
      <div className='flex flex-col lg:flex-row mx-auto lg:mt-10' >
        <span className='order-2 lg:order-1 mb-4 text-justify px-4'>
        <p className='flex robika text-xs sm:text-sm lg:text-base lg:text-lg xl:text-xl underlin block uppercase tracking-wide mb-3 font-bold'>sobre a empresa</p>
          <p className='md:text-lg text-sm font-thin'>
            &nbsp;Criada com o intuito de inovar e renovar ambientes, a Requinte Básico tráz a você e sua família o que há de melhor na linha de móveis, lazer e decoração.
            <br />&nbsp;Buscando sempre qualidade e sofisticação, para entregar ao cliente os melhores produtos do mercado.
            <br />&nbsp;Nosso objetivo é trazer a você conforto e satisfação, venha conhecer nossa empresa.
          </p>
        </span>
        {/* <img src={img} className='order-1 md:order-2 px-4 md:w-3/6 mb-4 md:mt-2' /> */}
        <div className='order-1 lg:order-2 px-4 lg:w-full mb-4 lg:mt-2' >
        <Img fluid={images.edges[0].node.childImageSharp.fluid} alt='' />
        </div>
      </div>
    </Layout>
  )
}

export default Empresa
